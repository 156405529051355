export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "上架单号",
    dataIndex: "shelve_order_number",
    fixed: "left",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "shelve_order_number" },
  },
  {
    title: "入库通知单号",
    dataIndex: "receipt_order_number",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "receipt_order_number" },
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => item.warehouse_item.name,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => item.client_item.name,
  },
  {
    title: "状态",
    dataIndex: "is_void",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => (item.is_void ? "已作废" : "已上架"),
  },
  {
    title: "操作人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
    customRender: (value, item) => item.creator_item.name,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 120,
    ellipsis: true,
  },
];
